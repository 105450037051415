/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {


	//==========================================================================================================================
	// Swiper Books animation (mobile only, <1000px)
	//==========================================================================================================================
	var nouveautesSwiper; // Declared out of function scope to be reusable in page specific functions

	function checkForSwiperNouveautes() {

		if( nouveautesSwiper === undefined ) {

			nouveautesSwiper = new Swiper ('#liste_nouveautes', {
				// Optional parameters
				direction: 'horizontal',
				loop: false,
				spaceBetween: 25,
				slidesPerView: 'auto',
				noSwiping: false, // "noSwiping: false" actually disables swiping. Confusing indeed.
				freeMode: true,
				freeModeMomentumRatio: 0.5,
				freeModeMomentumVelocityRatio: 0.5,
				simulateTouch: false,

				breakpoints: {
					// when window width is <= 767px
					768: {
						spaceBetween: 12,
					}
				}
			});
		}
	}





    var booksSlideAmount = function() {
        var totalWidth = 0;
        $('#liste_nouveautes .swiper-slide').each(function() {
            totalWidth += $(this).outerWidth(true);
        });

        return -(totalWidth - viewport().width + 50);
    };


	function loopNext(){

	    var booksList = $('#liste_nouveautes .swiper-wrapper')[0],
            booksAnim = TweenLite.to(booksList, 1, {x:booksSlideAmount(), rotation:0.01}, Power4.easeOut);
	}

	function loopPrev(){

	    var booksList = $('#liste_nouveautes .swiper-wrapper')[0],
            booksAnim = TweenLite.to(booksList, 1, {x:0, rotation:0.01}, Power4.easeOut);
	}



	function mouseOverNouveautes() {

		$('#liste_nouveautes_prev').on('mouseover', function() {
			loopPrev();
		});


		$('#liste_nouveautes_next').on('mouseover', function() {
			loopNext();
		});

	}


	// This Function will always return the initial font-size of the html element 
   	var rem = function rem() {
        var html = document.getElementsByTagName('html')[0];

        return function () {
            return parseInt(window.getComputedStyle(html).fontSize);
        };
    }();






	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Muchachos = {
		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages

				// Burger actions with Main Nav
				var navCloseTimeout;
				$('#menu-burger').on('click', function() {

					var headerHeight = $('header[role="banner"]').outerHeight();

					$(this).toggleClass('is-active');

					if( $(this).hasClass('is-active') ) {
						$('.hidden-menu').addClass('shown');
					}
					else {
						$('.hidden-menu').removeClass('shown');
					}

				});

				$('[role="alertdialog"]').find('.alert-close').click(function() {
					$(this).parents('[role="alertdialog"]').attr('aria-expanded', false);
					
					$cookie 		= $(this).attr('data-cookie');
					$expiration = $(this).attr('data-expiration');
					$nonce = $(this).attr('data-nonce');
					
					var ajaxurl = document.location.origin + '/wp-admin/admin-ajax.php',
							data = {
								action: 'set_alert_cookie',
								cookie: $cookie,
								expiration: $expiration,
								security: $nonce,
							};
					$.post(ajaxurl, data, function(response) {
						//
					});					

					
				});


			},
			scroll: function() {
				// JavaScript to be fired on scroll event
			},
			resize: function() {
				// JavaScript to be fired on resize event

				var actualRemValue = rem();
				
				normalizeHeights('.products', '.product img', '.loop-product-thumbnail-container');
				
// 				$('.article-hover-content').each(function() {
// 					var padding = 1.6 * actualRemValue;
// 
// 					var imgWidth = ( $(this).closest('.product').find('.loop-product-thumbnail-container').outerWidth() );
// 
// 					var productTitle =  $(this).closest('.product').find('.han-woocommerce-LoopProduct-container>.han-wrap-woocommerce-loop-product__title');
// 
// 					$(this).css('width', (imgWidth-padding)+'px');
// 
// 					productTitle.css('width', imgWidth+'px');
// 				});
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
				
				normalizeHeights('.products', '.product img', '.loop-product-thumbnail-container');
				
				// Force realc to avoid some errors
				setTimeout(function(){ 
					normalizeHeights('.products', '.product img', '.loop-product-thumbnail-container');
				}, 750);





				// Init ScrollReveaul
				window.sr = ScrollReveal({ 
					reset: false,
					duration: 750,
					distance: '5rem',
					scale: false,
					delay: 100,
					easing: 'ease-in-out',
				});

				
				sr.reveal('.reveal, .h-line-illu', { 
					distance: false, 
					opacity: null, 
					scale: false, 
					viewFactor: 0.35,
					
					afterReveal: function (_this) { $(_this).addClass('revealed') }
					 
				});
				
				sr.reveal('#main-navigation > *', { 
					duration: 250, 
					viewFactor: 0, 
					delay: 0, 
					distance: '1rem',
					
					beforeReveal: function (_this) { $(_this).parents('.intro-nav-wrapper').addClass('revealed') }
					
				}, 150);
				
				sr.reveal('.intro-nav-wrapper > .social-link', { duration: 350, viewFactor: 0, delay: 1750, distance: '2rem' });
				
				
				sr.reveal('.intro-content-wrapper > *', { duration: 1000, viewFactor: 0, delay: 1250, distance: '90%' }, 250);
				sr.reveal('body[class*="archive"] .products .product', { duration: 500, viewFactor: 0.4, delay: 750 }, 100);
				
				sr.reveal('#liste_nouveautes .products .product', { duration: 500, viewFactor: 0.25, delay: 750 }, 150);
				sr.reveal('.module-section_en_vedette .products .product', { duration: 500, viewFactor: 0.25, delay: 750 }, 150);
	




				
				$('a[href^="#"]:not([href="#"])').on('click', function(event) {
                    event.preventDefault();

                    var _target = $(this).attr('href');

                    scroll_to( _target, 0, 400, $(this) );

	                if ( $(event.target).closest('.hidden-menu').length ) {
						$('.hidden-menu').removeClass('shown');
						$('#menu-burger').removeClass('is-active');
	                }
                });

                // Close hidden-menu if click out of it
                $(document).on('click touchstart', function(event) {

                    if( $('.hidden-menu').hasClass('shown') ) {

	                    if ( !$(event.target).closest('.hidden-menu').length &&
	                    	 !$(event.target).closest('#menu-burger').length ) {

							$('.hidden-menu').removeClass('shown');
							$('#menu-burger').removeClass('is-active');
                        }
                    }
                });


                // Cheezy flags anim on pageload
                function triggerSwitch() {
	                $('.switch-current').addClass('switch-active').removeClass('switch-inactive');
                }

                var triggerSwitchTimeout = setTimeout(function() {
                	triggerSwitch();
                }, 1000);



                // Management on links inside products loop
                $('.product').on('mouseenter focus', function() {
                	var that = this;
                	var tempTimeout = setTimeout(function() {
                		$(that).find('.loop-hover-title').addClass('clickable');
                		$(that).find('.libraires-link').addClass('clickable');
                	}, 300, that);
                });

                $('.product').on('mouseleave blur', function() {
               		$(this).find('.loop-hover-title').removeClass('clickable');
            		$(this).find('.libraires-link').removeClass('clickable');
                });


                // Searchbox management
				$('.search-button').on('click', function() {
					$('#searchbox-container').toggleClass('shown');
				});

				// init controller
				var controller = new ScrollMagic.Controller();

				var buttons = $('*[class^="btn-"]');

				buttons.each(function (index, elem) {

					// create a scene
					new ScrollMagic.Scene({
							duration: 0,	// the scene should last for a scroll distance of 100px
							offset: 100		// start this scene after scrolling for 50px
						})
						.triggerElement(elem) // pins the element for the the scene's duration
						.setClassToggle(elem, "triggered")
						.triggerHook('onEnter')
						.addTo(controller); // assign the scene to the controller

				});

				// Mailchimp Form
			    var $form = $('#mc-embedded-subscribe-form');

			    if ( $form.length > 0 ) {
			        $form.find('[type="submit"]').bind('click', function ( event ) {
				        
						if ( event ) event.preventDefault();
							register($form);									
			        });
			    }
				
			},
		},
		// Home page
		'homepage': {
			resize: function() {
				// JavaScript to be fired on home page resize event

				

				if( $('#liste_nouveautes') ) {
					checkForSwiperNouveautes();
				}





			},
			scroll: function() {
				// JavaScript to be fired on scroll event
				
				// Remove search box on scroll when header is not sticky
				if ( $('#searchbox-container').hasClass('shown') && !$('[role="banner"]').hasClass('shown') )
					$('#searchbox-container').removeClass('shown');
				
                var windowTop = $(window).scrollTop(),
                	treshold = $('#section-1').offset().top;

                if(windowTop > treshold) {
                	$('header[role="banner"]').addClass('shown');
                	$('#searchbox-container').removeClass('top-snap');
                }
                else {
                	$('header[role="banner"]').removeClass('shown');
					$('.hidden-menu').removeClass('shown');
                	$('#searchbox-container').addClass('top-snap');
                }


			},
			finalize: function() {              
				// JavaScript to be fired on the home page, after the init JS

				if( $('#liste_nouveautes') ) {
					mouseOverNouveautes();
				}

				
			}
		},
		// Archive page
		'archive': {

			finalize: function() {

				// mobile cat-nav management
				$('#cat-nav-close').on('click', function() {

					$('#nav-categories-container').removeClass('shown');

				});

				$('#cat-nav-open-mobile').on('click', function() {
					$('#nav-categories-container').addClass('shown');
				});

			}
		}

	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Muchachos;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire common resize JS
			UTIL.fire('common', 'resize');

			// Fire common scroll JS
			UTIL.fire('common', 'scroll');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'resize');
				UTIL.fire(classnm, 'scroll');
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		},
		
		// Fire resize JS
		resizeEvents: function() {
			
			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm, 'resize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'resize');

		},
		// Fire resize JS
		scrollEvents: function() {
			
			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm, 'scroll');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'scroll');

		},
	};

	// Throttles for resource-intensive events
	var resizeTimer,scrollTimer;

	// Events
	$(document).ready(UTIL.loadEvents);

	$(window).resize(function(e) {
		clearTimeout(resizeTimer);
	    resizeTimer = setTimeout(function() {
			UTIL.resizeEvents();
	    }, 50);
	});

	$(window).scroll(function(e) {
		clearTimeout(resizeTimer);
	    resizeTimer = setTimeout(function() {
			UTIL.scrollEvents();
	    }, 2);
	});




	// ----------------------------- //
	// ---- FUNCTIONS GOES HERE ---- //
	// ----------------------------- //


	// Quick scroll to snippet
	function scroll_to( _target, _offset, _duration, _this ) {

		if ( _offset === 'undefined' ) 
			_offset = 0;

		if ( _duration === 'undefined' ) 
			_duration = 500;

		// Add header to our equation
		_offset = -$('[role="banner"]').outerHeight() + _offset;

		if ( _target == '#next' ) {
			_target = _this.closest('section').next('section');
			_destination = $(_target).offset().top + _offset;
		} else {
			_destination = $(_target).offset().top + _offset;
		}
	
	    $('html, body').animate({
	        scrollTop: _destination
	    }, _duration);

	}




    //=======================================
    // Google Analytics events
    //=======================================
    function ga_event_outbound_links(event) {

   		if (typeof ga == 'function') { 
			var target = $(this).attr('href');
    		ga('send', 'event', 'Outbound Links', target);
    	}
		else {
			console.log('Analytics deactivated');
		}
    }

    //=======================================
    // Mormalize heigts based on higher container
    //=======================================	
		function normalizeHeights( _container, _target, _parent) {
			
			_window_size = $(window).width();

//			if(_window_size >= 767) {
				if ( _target.length !== 0 ) {
					
					$(_container).each(function() {
						
						var maxHeight = -1;
						
						$(this).find(_target).each(function() {
														
							maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height(); 
							$(this).parents(_parent).attr('data-height', $(this).height()); 
						});
		
						// Look for parent
						if ( _parent.length !== 0 ) {
							
							$(this).find(_target).parents(_parent).each(function() {
								_currentHeight = $(this).attr('data-height');
								$(this).css('margin-top', maxHeight - _currentHeight + 'px');
		
							});
						} 
						
						// Or set element height instead
						else {
							
							$(this).find(_target).each(function() {
								_currentHeight = $(this).attr('data-height');
								$(this).css('margin-top', maxHeight - _currentHeight + 'px');
		
							});	
						}
				     
				  });
						
				}
//			}
// 			else {
// 	    		$(_parent).css("margin-top", "2rem");
// 	    	}
		}


    //=======================================
    // Accurate cross-browser viewport dimensions
    //=======================================
    function viewport() {
        var e = window, a = 'inner';
        if (!('innerWidth' in window )) {
            a = 'client';
            e = document.documentElement || document.body;
        }
        return { width : e[ a+'Width' ] , height : e[ a+'Height' ] };
    }



    //=======================================
    // Mailchimp ajax form function
    //=======================================
    function register($form) {

	    $('#mce-error-response').css('display','none');
	    $('#mce-success-response').css('display','none');

	    // var loaderWidth = $('#mc-embedded-subscribe').css('width');
	    // $('.ajax-loader').css({'display':'block', 'width':loaderWidth}); 


	    $.ajax({
	        type: $form.attr('method'),
	        url: $form.attr('action'),
	        data: $form.serialize(),
	        cache       : false,
	        dataType    : 'json',
	        contentType: "application/json; charset=utf-8",
	        error       : function(err) { 
	            $('#mce-error-response').css('display','block').html("Impossible de se connecter au serveur d'inscription, veuillez réessayer plus tard.");
	            // $('.ajax-loader').css('display','none');
	        },
	        success     : function(data) {
	        	console.log(data);
	            // $('.ajax-loader').css('display','none');
	            if (data.result != "success") {
	                // Something went wrong, do something to notify the user. maybe alert(data.msg);
	                switch(data.msg) {
					    case "0 - Please enter a value":
					        data.msg = "Veuillez entrer une valeur";
					        break;
					    case "0 - An email address must contain a single @":
					        data.msg = "Une adresse courriel doit contenir un @";
					        break;
					    case "0 - The domain portion of the email address is invalid (the portion after the @: )":
					    	data.msg = "Le domaine associé à votre courriel est invalide (la portion après le @)";
					    	break;
					    default:
					        data.msg = "Il y a une erreur dans le formulaire";
					}
					
	                $('#mce-error-response').css('display','block').html(data.msg);
	            } else {
	                // It worked, carry on...
	                $('#mce-success-response').css('display','block').html("Nous y sommes presque. Confirmez votre courriel afin de compléter votre inscription. Cliquez sur le lien présent dans l'email que nous vous avons envoyé.");
    				if (typeof ga == 'function') { 
    					ga('send', 'event', 'Mailchimp Form', 'Form Submit Success');
    				}
    				else {
    				}
	            }
	        }
	    });
	}

})(jQuery); // Fully reference jQuery after this point.